<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line/>
            <v-row v-else>
                <v-col cols="12">
                    <title-container title="Cuestionario"/>
                </v-col>
                <v-col cols="12" v-for="(question, i) in questionnaire" :key="i">
                    <div class="title-question">
                        {{ i + 1 }}. {{ question.text }}
                    </div>
                    <div v-if="question.type === 'check'" class="mt-3">
                        <div v-for="(option, j) in question.options" :key="j">
                            <v-checkbox
                                v-model="responses[i].text"
                                :label="option.text"
                                :value="option.text"
                                hide-details
                                class="pa-0 ma-0"
                            />
                            <div v-if="option.extra">
                                <kimsa-text-field
                                    v-if="responses[i].text.includes('Otro') || responses[i].text.includes('Otros')"
                                    @change="onChangeResponseExtra(i, $event)"
                                    :value="responses[i].extra"
                                    placeholder="Escriba aquí..."
                                    outlined dense
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="question.type === 'text'" class="mt-3">
                        <kimsa-text-field
                            v-if="question.suffix === 'IMC'"
                            readonly
                            @change="responses[i].text = $event"
                            :value="imc"
                            force-value
                            :suffix="question.suffix"
                            placeholder="Escriba aquí..."
                            outlined dense
                        />
                        <kimsa-text-field
                            v-else-if="question.suffix"
                            @change="responses[i].text = $event; setWeightHeight(question.suffix, $event)"
                            :suffix="question.suffix"
                            placeholder="Escriba aquí..."
                            outlined dense
                        />
                        <kimsa-text-field
                            v-else
                            @change="responses[i].text = $event"
                            :suffix="question.suffix"
                            placeholder="Escriba aquí..."
                            outlined dense
                        />
                    </div>
                    <div v-if="question.type === 'textarea'" class="mt-3">
                        <kimsa-textarea-field
                            @change="responses[i].text = $event"
                            placeholder="Escriba aquí..."
                            outlined
                        />
                    </div>
                    <div v-if="question.type === 'date'" class="mt-3">
                        <kimsa-date-picker
                            @change="responses[i].text = $event"
                            placeholder="DD-MM-AAAA"
                            outlined dense
                        />
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="title-question">
                        {{ lastQuestionNumber }}. ¿Es diabético?
                    </div>
                    <div class="mt-3">
                        <v-checkbox
                            v-model="hasDiabetes"
                            label="Si"
                            hide-details
                            class="pa-0 ma-0"
                        />
                        <v-checkbox
                            v-model="noHasDiabetes"
                            label="No"
                            hide-details
                            class="pa-0 ma-0"
                        />
                        <v-checkbox
                            v-model="noKnownHasDiabetes"
                            label="Nose"
                            hide-details
                            class="pa-0 ma-0"
                        />
                    </div>
                </v-col>
            </v-row>
            <play-video-dialog ref="video"/>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="finish" :loading="loadingFinish">
                Siguiente
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import httpService from "@/services/httpService";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaTextareaField from "@/components/common/inputs/KimsaTextareaField";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import TitleContainer from "@/components/elements/TitleContainer";
import PlayVideoDialog from "@/components/client/PlayVideoDialog";

export default {
    name: "ClientQuestionnaire",
    components: {
        PlayVideoDialog,
        TitleContainer, KimsaDatePicker, KimsaTextareaField, KimsaTextField, LoadingSpinner, MasterPanel},
    data() {
        return {
            loading: false,
            questionnaire: [],
            responses: [],
            extraOptions: [],
            height: 0,
            weight: 0,
            loadingFinish: false,
            hasDiabetes: false,
            noHasDiabetes: false,
            noKnownHasDiabetes: false,
        }
    },
    async mounted() {
        const vm = this
        await vm.loadQuestionnaire()
        if (vm.currentUser?.doctor?.youtube_link) vm.$refs.video.show()
    },
    computed: {
        imc(vm = this) {
            if (vm.height === 0) return 0
            let imc = Number(vm.weight / (vm.height * vm.height)).toFixed(1).replace('.0', '')
            return imc === 'NaN' ? 0 : imc
        },
        genderId(vm = this) {
            return vm.currentUser?.gender_id || null
        },
        lastQuestionNumber(vm = this) {
            return vm.questionnaire.length + 1
        },
    },
    methods: {
        async loadQuestionnaire() {
            const vm = this
            vm.loading = true

            let response = await httpService.get('client/questionnaire')
            // console.log('loadQuestionnaire', response)
            vm.questionnaire = response?.data?.questionnaire?.filter(question =>
                question.gender_id === null || question.gender_id === vm.genderId
            ) || []
            vm.responses = vm.questionnaire
                .map(question => ({
                    question_id: question.id,
                    text: question.type === 'check' ? [] : '',
                    extra: ''
                }));

            vm.loading = false
        },
        onChangeResponseExtra(questionIndex, value) {
            const vm = this
            vm.responses[questionIndex].extra = value
        },
        setWeightHeight(suffix, value) {
            const vm = this
            if (suffix === 'kg') vm.weight = Number(value || 0)
            if (suffix === 'cm') vm.height = Number(value || 0) / 100
        },
        async finish() {
            const vm = this
            let questionsUncompleted = vm.responses.filter(response => response.text === '' || response.text.length === 0)
            if (questionsUncompleted.length > 0 || (!vm.hasDiabetes && !vm.noHasDiabetes && !vm.noKnownHasDiabetes)) return vm.toast('Faltan preguntas por completar', 'error')

            vm.loadingFinish = true

            let responses = vm.responses.map((response) => {
                let copiedResponse = {...response}
                if (Array.isArray(copiedResponse.text)) {
                    copiedResponse.text.includes('Otro') || copiedResponse.text.includes('Otros') ?
                        copiedResponse.text = copiedResponse.text.map((t) => {
                            return t === 'Otro' || t === 'Otros' ? `${t}: ${copiedResponse.extra}` : t
                        }).join('\n')
                        : copiedResponse.text = copiedResponse.text.join('\n')
                }
                return {
                    user_id: vm.currentUser.id,
                    question_id: copiedResponse.question_id,
                    response: copiedResponse.text
                }
            })

            // console.log('responses', responses);
            await httpService.post('client/questionnaire/finished', {questionnaire: responses, has_diabetes: vm.hasDiabetes})

            vm.loadingFinish = false
            vm.goTo({name: 'client.questionnaire.finished'})
        }
    },
    watch: {
        hasDiabetes(val) {
            if (val) {
                this.noHasDiabetes = false
                this.noKnownHasDiabetes = false
            }
        },
        noHasDiabetes(val) {
            if (val) {
                this.hasDiabetes = false
                this.noKnownHasDiabetes = false
            }
        },
        noKnownHasDiabetes(val) {
            if (val) {
                this.hasDiabetes = false
                this.noHasDiabetes = false
            }
        },
    }
}
</script>

<style scoped>

.title-question {
    font-weight: bold;
}

.title-container {
    background-color: var(--v-secondary-base);
    border-radius: 0 12px 12px 0;
    padding: 16px 0;
    width: 85%;
}

.title-text {
    color: #FFFFFF;
}

</style>