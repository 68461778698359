<template>
    <div v-if="link">
        <iframe
            id="player" type="text/html"
            width="100%" :height="height"
            :src="src" class="youtube-container"
        />
    </div>
</template>

<script>
export default {
    name: "YoutubeIframe",
    props: {
        link: {
            type: String,
            default: null,
        },
        height: {
            type: [Number, String],
            default: 250,
        }
    },
    computed: {
        src(vm = this) {
            if(!vm.link) return null
            return 'https://www.youtube.com/embed/' + vm.link
        }
    }
}
</script>

<style scoped>

.youtube-container {
    border-radius: 8px;
    border: 0;
}

</style>