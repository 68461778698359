<template>
    <v-textarea
        :label="label"
        :color="color"
        v-model="model"
        @input="onChange"
        @click:clear="model = ''"
        :placeholder="placeholder"
        :rules="rules"
        :clearable="clearable"
        :disabled="disabled"
        auto-grow
        :rows="rows"
        :hide-details="!showDetails"
        :solo="solo"
        :flat="flat"
        :outlined="outlined"
        height="100%"
        ref="textarea"
    />
</template>

<script>
export default {
    name: "KimsaTextareaField",
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'secondary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: '',
        },
        rows: {
            type: [String, Number],
            default: 3,
        },
        showDetails: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: '',
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        focus() {
            const vm = this
            vm.$refs.textarea.focus()
        },
        onChange(value) {
            const vm = this
            if (!value) return vm.model = ''
            return vm.model = value
        },
        setDefaults() {
            const vm = this
            if (vm.value) vm.model = vm.value
        }
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

>>> .v-input__control {
    background: #FFFFFF;
    border-radius: 12px;
}

</style>