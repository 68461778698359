<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile>
            <v-toolbar color="primary" dark height="70px">
                <v-row align="center">
                    <v-col cols="2">
                        <v-img :src="imgIcon" contain max-width="40"/>
                    </v-col>
                    <v-col cols="8" class="text-truncate text-center">
                        Bienvenido
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon @click="hide">
                            <v-icon v-text="'mdi-close'"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-3">
                    <v-col cols="12">
                        <youtube-iframe :height="height" :link="link"/>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn color="secondary" class="normal-btn" small depressed @click="hide">
                            Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {STATUS} from "@/utils/Constants";
import YoutubeIframe from "@/components/common/YoutubeIframe";

export default {
    name: "PlayVideoDialog",
    components: {YoutubeIframe},
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        link(vm = this) {
            return vm.getYouTubeVideoId(vm.currentUser?.doctor?.youtube_link || null)
        },
        height() {
            return window.innerHeight / 2
        }
    },
    mounted() {
        const vm = this
        console.log('currentUser', vm.currentUser)
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
        },
        getYouTubeVideoId(url) {
            const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const match = url.match(pattern)
            return match?.[1] ? match[1] : null
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        }
    }
}
</script>

<style scoped>

</style>